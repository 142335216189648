import { memo } from "react"
import { NavLink } from "../NavLink"
import NoSsr from "../NoSsr"
import useT from "utils/useT"
import Image from "next/image"
import { getAuth, signOut } from "firebase/auth"
import createFirebaseApp from "firebase/firebase"
import useUnsavedStore from "stores/unsaved"
import useGlobalStore from "stores/global"
import useSessionStore from "stores/session"
import imagePlaceholder from "utils/imagePlaceholder"
import openShareSheet from "utils/openShareSheet"
import AppollowSocialMediaLinks from "components/AppollowSocialMediaLinks"

const SideBar = () => {
  const t = useT()
  const isDark = useGlobalStore((state) => state.isDark)
  const isAdmin = useSessionStore((state) => state.user.isAdmin)

  const setAppearance = useGlobalStore((state) => state.setAppearance)
  const toggleSidebar = useUnsavedStore((state) => state.toggleSidebar)
  const app = createFirebaseApp()
  const auth = getAuth(app)

  const photoURL = useSessionStore((state) => state.user.photoURL)
  const displayName = useSessionStore((state) => state.user.displayName)
  const uid = useSessionStore((state) => state.user.uid)
  const handleSignOut = () => {
    signOut(auth).catch((error) => console.error("Sign Out Error", error))
  }
  function closeSidebar(e: React.MouseEvent) {
    e.stopPropagation()
    toggleSidebar()
  }

  return (
    <div className="drawer" onClick={() => toggleSidebar()}>
      <div className="menu-header">
        <a
          onClick={() => {
            toggleSidebar()
            setAppearance(isDark ? "Light" : "Dark")
          }}
          className="border-right-0"
        >
          {isDark ? (
            <i className="fa font-12 color-yellow-dark fa-lightbulb"></i>
          ) : (
            <i className="fa font-12 color-night-light fa-moon"></i>
          )}
        </a>
        {/* <a href="#"  className="border-right-0">
            <i className="fa font-12 color-green-dark fa-brush"></i>
          </a> */}
        <a onClick={openShareSheet} className="border-right-0">
          <i className="fa font-12 color-red-dark fa-share-alt"></i>
        </a>
        <NavLink href="/settings" exact className="border-right-0">
          <i className="fa font-12 color-blue-dark fa-cog"></i>
        </NavLink>
        <a
          onClick={(e) => closeSidebar(e)}
          className="close-menu border-right-0"
        >
          <i className="fa font-12 color-red-dark fa-times"></i>
        </a>
      </div>
      <NoSsr>
        <div key={photoURL + uid} className="menu-logo text-center">
          <NavLink
            href={uid ? `/?userId=${uid}` : "/login"}
            as={uid ? `/profile/${uid}` : "/login"}
            exact
            className="border-right-0 d-flex px-3 gap-3 pb-3 align-items-center justify-content-center flex-wrap"
          >
            <Image
              className="rounded-circle bg-highlight img-profile"
              width={50}
              height={50}
              src={photoURL ? photoURL : "/images/avatars/4s.png"}
              alt="profile photo"
              priority
              placeholder="blur"
              blurDataURL={imagePlaceholder(isDark)}
              unoptimized
            />
            <span className="font-800 font-35">
              {displayName?.split(" ")[0] || t.sidebar.guest}
            </span>
          </NavLink>
        </div>
        <p dir="auto" className="font-11 text-center">
          {t.sidebar.welcometoAppollow}
        </p>
      </NoSsr>
      <div className="menu-items mb-4">
        <h5 className="text-uppercase opacity-20 font-12 pl-3">
          {t.sidebar.menu}
        </h5>
        <NavLink href="/" id="nav-welcome" className="nav-item-active">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
            data-feather-line="1"
            data-feather-size="16"
            data-feather-color="blue-dark"
            data-feather-bg="blue-fade-light"
            style={{ strokeWidth: 1, width: "16px", height: "16px" }}
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span>{t.sidebar.welcome}</span>
        </NavLink>
        {!uid && (
          <>
            <NavLink href="/signup" id="nav-starters">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-star"
                data-feather-line="1"
                data-feather-size="16"
                data-feather-color="yellow-dark"
                data-feather-bg="yellow-fade-light"
                style={{ strokeWidth: 1, width: "16px", height: "16px" }}
              >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
              </svg>
              <span>{t.sidebar.signup}</span>
              <i className="fa fa-circle"></i>
            </NavLink>

            <NavLink href="/login" id="nav-features">
              <i className="fa fa-sign-in-alt color-gray-dark"></i>
              <span>{t.sidebar.login}</span>
              <i className="fa fa-circle"></i>
            </NavLink>
          </>
        )}

        {uid && (
          <a id="nav-shapes" onClick={handleSignOut}>
            <i className="fa fa-sign-out-alt color-gray-dark"></i>
            <span>{t.sidebar.logout}</span>
            <i className="fa fa-circle"></i>
          </a>
        )}
        {isAdmin && (
          <>
            <NavLink href="/search-queries">
              <span>Search Queries</span>
              <i className="fa fa-circle"></i>
            </NavLink>
            <NavLink href="/noti-subs">
              <span>Notifications Subs</span>
              <i className="fa fa-circle"></i>
            </NavLink>
            <NavLink href="/accounts">
              <span>Accounts</span>
              <i className="fa fa-circle"></i>
            </NavLink>
          </>
        )}
        <NavLink href="/contact">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-mail"
            data-feather-line="1"
            data-feather-size="16"
            data-feather-color="blue-dark"
            data-feather-bg="blue-fade-light"
            style={{ strokeWidth: 1, width: "16px", height: "16px" }}
          >
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
            <polyline points="22,6 12,13 2,6"></polyline>
          </svg>
          <span>{t.sidebar.contact}</span>
          <i className="fa fa-circle"></i>
        </NavLink>
        <NavLink href="/settings" id="nav-settings">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-settings"
            data-feather-line="1"
            data-feather-size="16"
            data-feather-color="gray-light"
            data-feather-bg="gray-fade-light"
            style={{ strokeWidth: 1, width: "16px", height: "16px" }}
          >
            <circle cx="12" cy="12" r="3"></circle>
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
          </svg>
          <span>{t.sidebar.settings}</span>
          <i className="fa fa-circle"></i>
        </NavLink>
        <NavLink href="/about-us" id="nav-about-us">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-heart"
            data-feather-line="1"
            data-feather-size="16"
            data-feather-color="red-dark"
            data-feather-bg="red-fade-light"
            style={{ strokeWidth: 1, width: 16, height: 16 }}
          >
            <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
          </svg>
          <span>{t.sidebar.aboutUs}</span>
          <i className="fa fa-circle"></i>
        </NavLink>
        <a onClick={(e) => closeSidebar(e)} className="close-menu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
            data-feather-line="3"
            data-feather-size="16"
            data-feather-color="red-dark"
            data-feather-bg="red-fade-dark"
            style={{ strokeWidth: 3, width: "16px", height: "16px" }}
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
          <span>{t.sidebar.close}</span>
          <i className="fa fa-circle"></i>
        </a>
      </div>
      <div className="text-center mt-auto mb-3">
        <AppollowSocialMediaLinks />
        <p className="mb-0 pt-3 font-10 opacity-30">{t.footer.copyright}</p>
      </div>
    </div>
  )
}
export default memo(SideBar)
