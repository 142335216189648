import { AnimatePresence, motion } from "framer-motion"
import DragClose from "../DragClose"
import Backdrop from "../backdrop"
import SideBar from "./SideBar"
import useUnsavedStore from "stores/unsaved"
import useGlobalStore from "stores/global"
import { memo } from "react"
import Portal from "components/Portal"

const Container = () => {
  const openSideBar = useUnsavedStore((state) => state.openSideBar)
  const toggleSidebar = useUnsavedStore((state) => state.toggleSidebar)
  const transparency = useGlobalStore((state) => state.transparency)
  const animationToggle = useGlobalStore((state) => state.animationToggle)
  return (
    <Portal>
      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        mode="wait" // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {openSideBar && (
          <Backdrop className="sidebar-backdrop" onClick={toggleSidebar}>
            <DragClose
              callbackFn={toggleSidebar}
              dragElastic={{ left: 0 }}
              dragConstraints={{ top: 0, bottom: 0, left: 0 }}
            >
              <motion.div
                onClick={(e) => e.stopPropagation()}
                className={`sidebar-container ${
                  transparency ? "transparency" : ""
                }`}
                initial={animationToggle && { x: 270 }}
                animate={animationToggle && { x: 0 }}
                exit={
                  animationToggle
                    ? {
                        x: 270,
                      }
                    : undefined
                }
                transition={
                  animationToggle
                    ? {
                        duration: 0.3,
                        type: "keyframes",
                        ease: "easeOut",
                      }
                    : undefined
                }
              >
                <SideBar />
              </motion.div>
            </DragClose>
          </Backdrop>
        )}
      </AnimatePresence>
    </Portal>
  )
}

export default memo(Container)
